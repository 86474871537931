<template>
  <div class="c-app d-flex align-items-center min-vh-100" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">              
              <CForm>
                <h2>
                  <span>  
                  Registrarme como
                  </span>
                  <span v-if="isProvider">
                    proveedor
                  </span>
                  <span v-else>
                    cliente
                  </span>
                </h2>
                <br>
                  <b-row v-if="error">
                    <b-col cols="12">
                      <div class="alert alert-danger" role="alert" v-for="error_message in errors">
                        {{error_message}}
                      </div>
                    </b-col>
                </b-row>
                <p class="text-muted">Crea tu cuenta</p>
                <!-- <CInput
                  placeholder="Username"
                  autocomplete="username"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput> -->

                <CRow>                
                  <CCol col="6">                    
                    <CButton 
                      block 
                      color="facebook" 
                      @click="registerFacebook"
                      :disabled="!terms_and_conditions">
                        Facebook
                    </CButton>
                  </CCol>
                  <CCol col="6">
                    <CButton 
                      block 
                      @click="registerGoogle" 
                      :disabled="!terms_and_conditions">
                        <img src="@/assets/images/google-icon.svg" width="20" height="20" alt="icon-google" class="icon-google"/>
                        <span class="google-text">   Google</span>
                    </CButton>
                  </CCol>
                </CRow>

                <br>
                <CRow>
                  <CCol col="12" style="text-align:center;"> 
                    <strong>
                      <h5>
                      ó regístrate con
                      </h5>  
                    </strong> 
                  </CCol>  
                </CRow>

                <b-row>
                  <b-col md="12">
                    <!-- <label for="contact_email">
                        <strong>Tu email de contacto</strong>
                        <small class="alert-text"> *</small>
                    </label> -->
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text>@</b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input 
                        type="text"                          
                        placeholder="Email" 
                        autocomplete="email" 
                        v-model.trim="$v.email.$model"
                        :class="{'form-control is-invalid': $v.email.$error,
                          'form-control is-valid': !$v.email.$invalid}"/>
                      <b-form-invalid-feedback v-if="!$v.email.required">
                        Tu email de contacto es necesario
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.email.email">
                          Tu email de contacto no parece valido
                      </b-form-invalid-feedback>
                    </b-input-group>


                    <!-- <b-form-group>
                      <b-form-input 
                        type="text"
                        id="address"
                        v-model.trim="$v.email.$model"
                        :class="{'form-control is-invalid': $v.email.$error,
                          'form-control is-valid': !$v.email.$invalid}">
                      </b-form-input>
                      <b-form-invalid-feedback v-if="!$v.email.required">
                          Tu email de contacto es necesario
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.email.email">
                          Tu email de contacto no parece valido
                      </b-form-invalid-feedback>
                    </b-form-group> -->
                  </b-col> 

                  <b-col lg="12">
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text>
                          <CIcon name="cil-lock-locked"/>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input 
                        type="password"                          
                        placeholder="Contraseña"                         
                        v-model.trim="$v.password.$model"
                        :class="{'form-control is-invalid': $v.password.$error,
                          'form-control is-valid': !$v.password.$invalid}"/>
                      <b-form-invalid-feedback v-if="!$v.password.required">
                        La contraseña es obligatoria.
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.password.minLength">
                          La contraseña debe tener como mínimo {{$v.password.$params.minLength.min}} carácteres.
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-col>
                  
                  <b-col lg="12">
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text>
                          <CIcon name="cil-lock-locked"/>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input 
                        type="password"                          
                        placeholder="Contraseña"                         
                        v-model.trim="$v.password_confirmation.$model"
                        :class="{'form-control is-invalid': $v.password_confirmation.$error,
                          'form-control is-valid': !$v.password_confirmation.$invalid}"/>
                      <b-form-invalid-feedback v-if="!$v.password_confirmation.required">
                        La confirmación de contraseña es obligatoria
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.password_confirmation.minLength">
                        La contraseña debe tener como mínimo {{$v.password.$params.minLength.min}} carácteres.
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.password_confirmation.sameAsPassword">
                        Las contraseñas deben ser iguales.
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>

                <!-- <CInput
                  placeholder="Email"
                  autocomplete="email"
                  prepend="@"
                  v-model="email"
                /> -->
                <!-- <CInput
                  placeholder="Contraseña"
                  type="password"
                  autocomplete="new-password"
                  v-model="password"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput> -->
                <!-- <CInput
                  placeholder="Repetir contraseña"
                  type="password"
                  autocomplete="new-password"
                  v-model="password_confirmation"
                  class="mb-4"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput> -->

                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="gridCheck" v-model="terms_and_conditions">
                    <label class="form-check-label" for="gridCheck">
                      Al continuar aceptas los
                      <a v-on:click="goTo('termsProvider')"
                        v-if="isProvider">
                        <span class="text-link-color">
                          Términos y Condiciones para proveedores
                        </span>
                      </a>
                      <a v-on:click="goTo('terms')"
                        v-else>
                        <span class="text-link-color">
                          Términos y Condiciones
                        </span>
                      </a>
                      de Go Guau
                    </label>
                </div>
                <br>               
                

                <CButton 
                  color="success" 
                  block 
                  @click="submit"
                  :disabled="disableRegister">
                    Registrarme
                </CButton>
              </CForm>
            </CCardBody>

            <hr>
          
            <b-list-group flush style="border-color:white;">
              <b-list-group-item>
                Ya tienes usuario y contraseña?
                <button
                  type="button"
                  class="btn btn-link text-link-color"
                  style="padding-top:0px; padding-left:0"
                  @click.prevent="goTo('login')">
                Ir a iniciar sesión
                </button>
              </b-list-group-item>
            </b-list-group>
          </CCard>         
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators';
export default {
  name: 'Register',  
  data(){
    return {
      email: '',
      password: '',
      password_confirmation: '',
      terms_and_conditions: true,      
      error: false,
      errors: [],
      redirect: null,   
      text: '1',
      isProvider: false  
    }
  },
  created() {    
    if(this.$route.query)
      this.isProvider = this.$route.query.provider      
        
  },
  computed: {
    disableRegister(){
      if(!this.terms_and_conditions){
        return true
      } else {
        return false
      }
    }
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6)
    },
    password_confirmation: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs('password') 
    },
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) {                
        this.error = true
        this.errors = ['No has completado correctamente tus datos']                
      } else {        
        this.signup()
      }
    },
    signup: async function() {
      this.error = false
      this.errors = []
      if (this.password == this.password_confirmation){
        try {
            let response = await this.$firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
              if ('user' in response){
                var user = response.user;
                var token = await user.getIdToken()
                var userId = user.uid
                this.$store.commit('defUserId', userId)
                this.redirectLogin()
              } else {
                  this.error = true                  
                  this.errors.push('No fue posible completar el registro')
                  this.errors.push(this.$t('login.errorMessages.couldNotInitSession'))
              }
        } catch (error) {   
          this.error = true
          if (error != null && 'code' in error){
            if (error.code == 'auth/email-already-in-use'){
              this.errors.push('Este email ya se encuentra en uso')
            } else if (error.code == 'auth/weak-password'){
              this.errors.push('mejora tu contraseña, mínimo 6 caracteres')
            } else if (error.code == 'auth/invalid-email'){
              this.errors.push('Email invalido')
            } else {                
                this.errors.push('No fue posible completar el registro')
                this.errors.push(error)
            }
          } else {            
            this.errors.push('No fue posible completar el registro')
            this.errors.push(error)
          }
        }
      } else {
        this.error = true
        this.errors = ['La contraseña y la confirmación no coinciden']
      }
    },
    goTo (to) {
      if(to == 'terms'){
        let route = this.$router.resolve({path: '/pages/terms'});
        window.open(route.href, '_blank');
      }
      else if(to == "termsProvider"){
        let route = this.$router.resolve({path: '/pages/termsprovider'});
        window.open(route.href, '_blank');
      }
      else if(to == "login"){
        this.$router.replace('/pages/login')
      }
    },
    registerFacebook: function(){
        const facebook_provider = new this.$firebase.auth.FacebookAuthProvider()
        this.providerLogin(facebook_provider)
    },
    registerGoogle: function(){
      const  google_provider = new this.$firebase.auth.GoogleAuthProvider()
      this.providerLogin(google_provider)
    },
    providerLogin: async function(provider){
        this.error = false
        this.errors = []
        try {
            let response = await this.$firebase.auth().signInWithPopup(provider)
            if ('user' in response){
              var user = response.user;
              var token = await user.getIdToken()
              var userId = user.uid
              this.$store.commit('defUserId', userId)
              this.redirectLogin()
            }
        }
        catch(error) {
          // Handle Errors here.   
          if(error == 'Error: An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.'){
            this.errors = 
            [
              'No fue posible completar el registro',
              'Esta cuenta ya ha sido registrada con otro proveedor. Inicia sesión con el proveedor registrado'
            ]
          } else {
            this.errors = ['No fue posible completar el registro']
          }
          this.error = true
        }
    },
    redirectLogin: function(){
        if (this.redirect){
          if (this.redirect.redirect == 'project'){
            this.$router.replace('/projects/project/' + this.redirect.id)
          }else if(this.redirect.redirect == 'service'){              
            if(this.redirect.code != null && this.redirect.code != 0){                
              this.$router.push({ path: '/services/profile/'+ this.redirect.id, query: { 'code': this.redirect.code} })
            }else{                
              this.$router.replace('/services/profile/' + this.redirect.id)                
            }
          }else {
            this.$router.push('/dashboard')
          }
        } else {
          console.log('redirectLogin: ', this.isProvider)
          if(this.isProvider){
            // this.$router.push('/providers/dashboard')
            this.$router.push({name: 'providers-users-create'})
          } else {
            this.$router.push('/dashboard')
          }
        }
    },
  }
  
}
</script>
